.AutoCompleteText {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.73);
  }
  
  .AutoCompleteText input {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.73);
    padding: 10px 5px;
    box-sizing: border-box;
    outline: none;
  }
  
  .AutoCompleteText ul {
    list-style: none;
    text-align: left;
    margin: 0;
    padding: 0;
    border: 1px solid rgba(128, 128, 128, 0.81);
    border-top: none;
  }
  
  .AutoCompleteText ul::before {
    content: "";
  }
  
  .AutoCompleteText li {
    padding: 10px 5px;
    cursor: pointer;
  }
  .AutoCompleteText li:hover {
    text-decoration: underline;
    background-color: rgba(128, 128, 128, 0.2);
  }