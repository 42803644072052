.SearchForm{
    background-color: #b51e1e;
    min-height:300px;
    width:100%;
    box-shadow: 0 13px 18px rgba(27,31,35,.2);
    padding: 20px;
    color:#fff;
}

.form-input, input.location-search-input{
    width: 100%;
    padding: 17px 15px 15px 15px;
    font-size: 14px;
    border: 1px solid rgba(27,31,35,.2);
    background-color: #fff;
    border-radius: 4px;
    margin:5px;
}

.suggestion-item, .suggestion-item--active{color: #000 !important; padding: 5px;}