@import url(https://fonts.googleapis.com/css?family=Nunito+Sans&display=swap);

.react-icons{color: #32dbc6;text-align:center;}

.med-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 1.25;
    color:#053c72;
    margin-left:10%;
    margin-top:10px;
    margin-bottom:10px;
}

.deleteImage{display:none !important;}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #f23a2e;
}
a {
    color: #f23a2e;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.site-navigation li a {
    font-size: 12px !important;
    line-height: 1.4;
    font-family:"AT-Rubik",
    Arial,sans-serif !important;
    text-transform: uppercase; font-weight: 900 !important;
    color:#495057 !important}
.site-navigation li {padding: 10px;}

.site-navigation li a:hover {color: #f23a2e !important; }
.site-navigation li a{vertical-align: top !important;}
.SearchForm{
    background-color: #b51e1e;
    min-height:300px;
    width:100%;
    box-shadow: 0 13px 18px rgba(27,31,35,.2);
    padding: 20px;
    color:#fff;
}

.form-input, input.location-search-input{
    width: 100%;
    padding: 17px 15px 15px 15px;
    font-size: 14px;
    border: 1px solid rgba(27,31,35,.2);
    background-color: #fff;
    border-radius: 4px;
    margin:5px;
}

.suggestion-item, .suggestion-item--active{color: #000 !important; padding: 5px;}
.select-search {
    width: 100%;
    position: relative;
    margin:5px;
    font-family: 'Nunito Sans', sans-serif;
    box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
    box-sizing: inherit;
}

    .select-search.is-disabled {
        opacity: 0.5;
    }

    .select-search__value {
        position: relative;
    }

    .select-search__value::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 19px;
        width: 11px;
        height: 11px;
        transform: rotate(45deg);
        border-right: 1px solid #000;
        border-bottom: 1px solid #000;
        pointer-events: none;
    }

    .select-search__input {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 16px;
        background: #fff;
        border: none;
        box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
        border-radius: 3px;
        outline: none;
        font-family: 'Noto Sans', sans-serif;
        font-size: 14px;
        text-align: left;
        line-height: 36px;
        -webkit-appearance: none;
    }

    .select-search:not(.is-disabled) .select-search__input {
        cursor: pointer;
    }

    .select-search--multiple .select-search__input {
        cursor: auto;
        cursor: initial;
    }

    .select-search__input::-webkit-search-decoration,
    .select-search__input::-webkit-search-cancel-button,
    .select-search__input::-webkit-search-results-button,
    .select-search__input::-webkit-search-results-decoration {
        -webkit-appearance:none;
    }

    .select-search--multiple .select-search__input {
        border-radius: 3px 3px 0 0;
    }

    .select-search__input:hover {
        border-color: #2FCC8B;
    }

    .select-search__input:not([readonly]):focus {
        cursor: auto;
        cursor: initial;
    }

    .select-search__select {
        background: #fff;
        box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15);
    }

    .select-search:not(.select-search--multiple) .select-search__select {
        position: absolute;
        z-index: 2;
        top: 44px;
        right: 0;
        left: 0;
        border-radius: 3px;
        overflow: auto;
        max-height: 360px;
    }

    .select-search--multiple .select-search__select {
        height: 180px;
        overflow: auto;
        border-top: 1px solid #eee;
        border-radius: 0 0 3px 3px;
    }

    .select-search__options {
        list-style: none;
    }

    .select-search__row:not(:first-child) {
        border-top: 1px solid #eee;
    }

    .select-search__option {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 16px;
        background: #fff;
        border: none;
        outline: none;
        font-family: 'Noto Sans', sans-serif;
        font-size: 14px;
        text-align: left;
        cursor: pointer;
    }

    .select-search--multiple .select-search__option {
        height: 48px;
    }

    .select-search__option.is-selected {
        background: #2FCC8B;
        color: #fff;
    }

    .select-search__option.is-highlighted,
    .select-search__option:not(.is-selected):hover {
        background: rgba(47, 204, 139, 0.1);
    }

    .select-search__option.is-highlighted.is-selected,
    .select-search__option.is-selected:hover {
        background: #2eb378;
        color: #fff;
    }

    .select-search__group-header {
        font-size: 10px;
        text-transform: uppercase;
        background: #eee;
        padding: 8px 16px;
    }
.AutoCompleteText {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.73);
  }
  
  .AutoCompleteText input {
    width: 100%;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.73);
    padding: 10px 5px;
    box-sizing: border-box;
    outline: none;
  }
  
  .AutoCompleteText ul {
    list-style: none;
    text-align: left;
    margin: 0;
    padding: 0;
    border: 1px solid rgba(128, 128, 128, 0.81);
    border-top: none;
  }
  
  .AutoCompleteText ul::before {
    content: "";
  }
  
  .AutoCompleteText li {
    padding: 10px 5px;
    cursor: pointer;
  }
  .AutoCompleteText li:hover {
    text-decoration: underline;
    background-color: rgba(128, 128, 128, 0.2);
  }
label{color: #000; font-weight: 600; padding-bottom: 5px; padding-top:5px;}
