
.react-icons{color: #32dbc6;text-align:center;}

.med-title {
    font-weight: 900;
    font-size: 16px;
    line-height: 1.25;
    color:#053c72;
    margin-left:10%;
    margin-top:10px;
    margin-bottom:10px;
}

.deleteImage{display:none !important;}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: #fff;
    background-color: #f23a2e;
}
a {
    color: #f23a2e;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

.site-navigation li a {
    font-size: 12px !important;
    line-height: 1.4;
    font-family:"AT-Rubik",
    Arial,sans-serif !important;
    text-transform: uppercase; font-weight: 900 !important;
    color:#495057 !important}
.site-navigation li {padding: 10px;}

.site-navigation li a:hover {color: #f23a2e !important; }
.site-navigation li a{vertical-align: top !important;}